body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.poppins {
  font-family: 'Poppins',
  sans-serif;
}


.accordion-button:not(.collapsed) {
  box-shadow: none !important; 
  color: inherit !important;
}

.accordion-button::after {
  color : black !important;
}

