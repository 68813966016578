.c_card {
    width: 100%;
    margin: auto;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px,
    rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
    rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border: none;
    display: flex;
    flex-direction: row;
    padding : 10px 0px
}

.imageDiv {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.informationDiv {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
}