.card {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
    border-radius: 20px;
    overflow: hidden;
}

.c_image {
    flex-shrink: 0;
}


